.top_select[data-v-585052c4] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.box[data-v-585052c4] {
  width: 95%;
  margin: 0 auto;
}
.list_main[data-v-585052c4] {
  padding-bottom: 10px;
  border-bottom: 1px solid #efefef;
  display: flex;
  align-items: center;
}
.list_main .each[data-v-585052c4] {
  width: 15%;
  margin-top: 20px;
  text-align: center;
}
.list_main .title[data-v-585052c4] {
  font-size: 13px;
  color: #999;
  padding-bottom: 10px;
}
.list_main .area[data-v-585052c4] {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.product[data-v-585052c4] {
  width: 100px;
  text-align: center;
  cursor: pointer;
  margin-left: 20px;
}
.product img[data-v-585052c4] {
  width: 30px;
  height: 30px;
}
.product p[data-v-585052c4] {
  font-size: 14px;
}
.card[data-v-585052c4] {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card_each[data-v-585052c4] {
  width: 23%;
}
.card_info[data-v-585052c4] {
  color: #e60000;
  margin-bottom: 15px;
  font-size: 14px;
}
.card_title[data-v-585052c4] {
  color: #999;
  font-size: 14px;
}
.box[data-v-585052c4] {
  margin-top: 20px;
}
.el-col[data-v-585052c4] {
  border-right: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  padding: 20px 32px;
  font-size: 14px;
}
.el-col[data-v-585052c4]:last-child {
  border-right: none;
}
.el-col-4[data-v-585052c4] {
  text-align: center;
}
.tab[data-v-585052c4] {
  margin-left: 20px;
  font-size: 14px;
}
.tab span[data-v-585052c4] {
  transition: 0.3s;
  cursor: pointer;
  line-height: 22px;
  background-image: linear-gradient(#17a2b8, #17a2b8);
  background-position: bottom;
  background-size: 0 2px;
  background-repeat: no-repeat;
  padding-bottom: 5px;
  margin-right: 40px;
}
.tab .active[data-v-585052c4] {
  color: #17a2b8;
  background-size: 100% 2px;
}
.div_p[data-v-585052c4] {
  line-height: 30px;
  border-bottom: 1px solid #efefef;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.div_p[data-v-585052c4]:last-child {
  border-bottom: none;
}
.main[data-v-585052c4] {
  width: 100%;
  height: calc(100vh - 96px);
  position: relative;
  display: flex;
  align-items: center;
}
.main_left[data-v-585052c4] {
  width: 45%;
  height: 100%;
  background-image: linear-gradient(to right, #eff9fb, #fefeff);
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main_left img[data-v-585052c4] {
  width: 90%;
}
.main_right[data-v-585052c4] {
  float: left;
}
.main_right h3[data-v-585052c4] {
  margin-bottom: 15px;
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: 600;
}
.main_right h5[data-v-585052c4] {
  margin-bottom: 10px;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 600;
}
.pic[data-v-585052c4] {
  width: 150px;
  position: absolute;
  top: 0;
  right: 0;
}
.pic img[data-v-585052c4] {
  width: 100%;
}
.each_content[data-v-585052c4] {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.each_content .el-icon[data-v-585052c4] {
  font-size: 20px;
  color: #5aca8b;
  margin-right: 3px;
}
.each_content p[data-v-585052c4] {
  color: #474747;
  font-size: 14px;
}
[data-v-585052c4] .el-collapse-item__content {
  padding-bottom: 0;
}
[data-v-585052c4] .el-collapse-item__header {
  height: 30px;
  line-height: 30px;
}
.form_style .el-form-item[data-v-585052c4] {
  margin-bottom: 5px;
}
.filter_border[data-v-585052c4] {
  border: 1px solid #efefef;
  border-radius: 4px;
  box-shadow: 0 2px 10px #ddd;
  padding: 0 20px;
}